// App.js
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Main from './layouts/Main';
import NotFound from "./pages/NotFound";
import PublicRoutes from "./routes/PublicRoutes";
import ReactGA from "react-ga4";
import ProtectedRoute from "./components/ProtectedRoute";
import protectedRoutes from "./routes/ProtectedRoutes";


import "./assets/css/remixicon.css";
import "./scss/style.scss";

ReactGA.initialize("G-K2TD4PBHS9");

window.addEventListener("load", function () {
  let skinMode = localStorage.getItem("skin-mode");
  let HTMLTag = document.querySelector("html");

  if (skinMode) {
    HTMLTag.setAttribute("data-skin", skinMode);
  }
});

export default function App() {
  ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  return (
    <React.Fragment>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main />}>
            {protectedRoutes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={
                  <ProtectedRoute 
                    component={route.component} 
                    authorizedUserTypes={route.authorizedUserTypes} 
                  />
                }
              />
            ))}
          </Route>
          {PublicRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={route.element}
            />
          ))}
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </React.Fragment>
  );
}