import React, { useEffect, useState } from "react";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { Link } from "react-router-dom";
import { Form, Button, Card, Col, Nav, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";
import ReactApexChart from "react-apexcharts";
import { VectorMap } from "@react-jvectormap/core";
import { worldMill } from "@react-jvectormap/world";
import { usMill } from "@react-jvectormap/unitedstates";
import axios from "axios";

export default function WebsiteAnalytics() {
  // State management
  const [newUsersData, setNewUsersData] = useState([]);
  const [totalUsersData, setTotalUsersData] = useState([]);
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);
  const [skin, setSkin] = useState(localStorage.getItem('skin-mode') || 'light');
  const [isUSView, setIsUSView] = useState(false); // Toggle between World and US view

  const [userType, setUserType] = useState('');
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [notificationStatus, setNotificationStatus] = useState(null);

  // Function to switch the skin mode
  const switchSkin = (skin) => {
    const btnWhite = document.getElementsByClassName('btn-white');
    const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');
    if (skin === 'dark') {
      for (const btn of btnWhite) {
        btn.classList.add('btn-outline-primary');
        btn.classList.remove('btn-white');
      }
    } else {
      for (const btn of btnOutlinePrimary) {
        btn.classList.remove('btn-outline-primary');
        btn.classList.add('btn-white');
      }
    }
  };

  // Initial setup effect
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  // Fetching user and analytics data
  useEffect(() => {
    const userItem = localStorage.getItem('user');
    if (!userItem) {
      setError('User is not authenticated');
      return;
    }

    const parsedUser = JSON.parse(userItem);
    setUser(parsedUser);

    if (!parsedUser.token) {
      setError('Auth token is null or invalid');
      return;
    }

    const fetchData = async () => {
      try {
        const axiosConfig = {
          headers: {
            'Authorization': `Bearer ${parsedUser.token}`,
          }
        };

        const [newUsersResponse, totalUsersResponse] = await Promise.all([
          axios.get('/analytics/new-users-per-day', axiosConfig),
          axios.get('/analytics/total-users-per-day', axiosConfig),
        ]);

        setNewUsersData(newUsersResponse.data);
        setTotalUsersData(totalUsersResponse.data);
      } catch (err) {
        console.error('Error fetching data:', err.message);
        setError('Failed to load analytics data');
      }
    };

    fetchData();
  }, []);


  const handleNotificationSubmit = async (e) => {
    e.preventDefault();
    
    if (!user) {
      setNotificationStatus('User is not authenticated');
      return;
    }

    try {
      const axiosConfig = {
        headers: {
          'Authorization': `Bearer ${user.token}`,
        }
      };

      const response = await axios.post('/send-notification-cust', {
        user_type: userType,
        title: title,
        message: message
      }, axiosConfig);

      setNotificationStatus(response.data.status);
    } catch (err) {
      console.error('Error sending notification:', err.message);
      setNotificationStatus('Failed to send notification');
    }
  };




  // Early return if there's an error
  if (error) {
    return <div>Error: {error}</div>;
  }

  // Toggle between Global and US views
  const toggleMapView = () => setIsUSView(!isUSView);

  const newUsersChartOptions = {
    chart: {
      type: 'line',
      height: 350,
      zoom: { enabled: false },
    },
    dataLabels: { enabled: false },
    stroke: { curve: 'straight' },
    title: {
      text: 'New Users Per Day',
      align: 'left',
    },
    grid: { row: { colors: ['#f3f3f3', 'transparent'], opacity: 0.5 } },
    xaxis: {
      categories: newUsersData.map(item => item.date),
    },
  };

  const newUsersChartSeries = [{
    name: "New Users",
    data: newUsersData.map(item => item.new_users_count),
  }];

  const totalUsersChartOptions = {
    chart: {
      type: 'line',
      height: 350,
      zoom: { enabled: false },
    },
    dataLabels: { enabled: false },
    stroke: { curve: 'straight' },
    title: {
      text: 'Total Users Per Day',
      align: 'left',
    },
    grid: { row: { colors: ['#f3f3f3', 'transparent'], opacity: 0.5 } },
    xaxis: {
      categories: totalUsersData.map(item => item.date),
    },
  };

  const totalUsersChartSeries = [{
    name: "Total Users",
    data: totalUsersData.map(item => item.total_users),
  }];

  // Placeholder data for top countries and states
  const topCountries = [
    { bg: "primary", country: "United States", amount: "2000 Users" },
    { bg: "success", country: "India", amount: "1500 Users" },
    { bg: "info", country: "Australia", amount: "1200 Users" },
  ];

  const topStates = [
    { bg: "primary", state: "California", amount: "500 Users" },
    { bg: "success", state: "Texas", amount: "450 Users" },
    { bg: "info", state: "Florida", amount: "400 Users" },
  ];

  const regStyle = {
    selected: { fill: "#506fd9" },
    initial: { fill: "#d9dde7" },
  };

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><Link href="/">Dashboard</Link></li>
              <li className="breadcrumb-item active" aria-current="page">User Analytics</li>
            </ol>
            <h4 className="main-title mb-0">User Analytics</h4>
          </div>
          <Nav as="nav" className="nav-icon nav-icon-lg">
            <OverlayTrigger overlay={<Tooltip>Share</Tooltip>}>
              <Nav.Link href=""><i className="ri-share-line"></i></Nav.Link>
            </OverlayTrigger>
            <OverlayTrigger overlay={<Tooltip>Print</Tooltip>}>
              <Nav.Link href=""><i className="ri-printer-line"></i></Nav.Link>
            </OverlayTrigger>
            <OverlayTrigger overlay={<Tooltip>Report</Tooltip>}>
              <Nav.Link href=""><i className="ri-bar-chart-2-line"></i></Nav.Link>
            </OverlayTrigger>
          </Nav>
        </div>

        <Row className="g-3 justify-content-center">
          <Col md="6" xl="6">
            <Card className="card-one">
              <Card.Body>
                <ReactApexChart series={newUsersChartSeries} options={newUsersChartOptions} type="line" height={350} />
              </Card.Body>
            </Card>
          </Col>

          <Col md="6" xl="6">
            <Card className="card-one">
              <Card.Body>
                <ReactApexChart series={totalUsersChartSeries} options={totalUsersChartOptions} type="line" height={350} />
              </Card.Body>
            </Card>
          </Col>

          <Col md="12">
            <Card className="card-one">
              <Card.Body>
                <h5 className="card-title">Send Custom Notification</h5>
                <Form onSubmit={handleNotificationSubmit}>
                  <Form.Group className="mb-3">
                    <Form.Label>User Type</Form.Label>
                    <Form.Select value={userType} onChange={(e) => setUserType(e.target.value)} required>
                      <option value="">Select User Type</option>
                      <option value="1">user_type 1: free plan</option>
                      <option value="2">user_type 2: premium plan</option>
                    </Form.Select>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      type="text"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      maxLength="255"
                      required
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                      as="textarea"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      required
                    />
                  </Form.Group>

                  <Button variant="primary" type="submit">Send Notification</Button>
                </Form>
                {notificationStatus && (
                  <p className="mt-3">{notificationStatus}</p>
                )}
              </Card.Body>
            </Card>
          </Col>

          <Col xs="12">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Sessions By Location</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href="" onClick={toggleMapView}>
                    {isUSView ? "View World Map" : "View US Map"}
                  </Nav.Link>
                  <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                  <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body className="p-4">
                <Row className="align-items-center g-3">
                  <Col md="4" className="d-flex flex-column justify-content-center">
                    <Table className="table-one mb-4">
                      <thead>
                        <tr>
                          <th className="wd-40 pt-0">{isUSView ? "Top States" : "Top Countries"}</th>
                          <th className="wd-60 pt-0">&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody>
                        {(isUSView ? topStates : topCountries).map((item, index) => (
                          <tr key={index}>
                            <td>
                              <span className={"badge-dot me-2 bg-" + item.bg}></span>
                              <span className="fw-medium">{isUSView ? item.state : item.country}</span>
                            </td>
                            <td>{item.amount}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>

                    <Button variant="white" className="btn-sm">Show Full Report</Button>
                  </Col>
                  <Col md="8" className="mt-5 mt-md-0">
                    <VectorMap 
                      map={isUSView ? usMill : worldMill} 
                      backgroundColor={(skin === "dark") ? "#192030" : "#fff"} 
                      regionStyle={regStyle} 
                      selectedRegions={isUSView ? ["CA", "TX", "FL"] : ["US", "IN", "AU"]} 
                      className="vmap-one" 
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Footer />
      </div>
    </React.Fragment>
  );
}
